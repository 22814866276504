// Fanplayr Adaptor (solgar) v1.0.12
// Hodor v1.6.2
(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
var dlReader = module.exports = {};
var platform = require('__hodor/platform.js');
var _ = require('__hodor/utils.js');
var state = require('__hodor/state.js');
var page = state.page;
var product = page.product;
var cart = state.cart;
var order = state.order;

/*
This module search element in data layer. You could get directly the element, waiting for it, 
or remain listening to check for it (for example in data layer tracking event)

For the test, you could pass a function that must return a boolen, that it will be called with data layer element as parameter.
Otherwise you could pass a string, and it return if the element has this property
*/

/**
 * Description: Continue wathcing data layer for elements that passed the test, and call the callback
 * 
 * @param {function||string}    testFnOrProp    Test executed on data layer element. If function, called with element as input, if string, check if element has the property
 * @param {function}            callback        Funciton executed with the element that passed the test as input
 * @param {number}              delay           Time between one check and another (in millisecond)
 */
function watchDataLayerForElement(testFnOrProp, callback, delay) {
    delay = delay || 500;
    var index = 0;
    var interval = setInterval(function () {
        var dataLayer = window.dataLayer || [];
        while (index < dataLayer.length) {
            if (checkCondition(testFnOrProp, dataLayer[index])) {
                callback(dataLayer[index++], interval);
            } else {
                index++;
            }
        }
    }, delay);
}
dlReader.watchDataLayerForElement = watchDataLayerForElement;

/** 
 * Description: Return a promise that resolve when a element in data layer pass the test, rejected when timeout
 * 
 * @param {function|string}     testFnOrProp    Test executed on data layer element. If function, called with element as input, if string, check if element has the property
 * @param {function}            callback        Callback function called when test pass
 * @param {number}              timeout         Time before the function is rejected (in millisecond)
 * @param {number}              delay           Time between one check and another (in millisecond)
*/
dlReader.waitDataLayerElement = function (testFnOrProp, callback, timeout, delay) {
    timeout = timeout || 0;
    delay = delay || 500;
    var interval = window.setInterval(function () {
        searchElement(testFnOrProp, callback, interval);
    }, delay);
    if (timeout > 0) {
        window.setTimeout(function () {
            window.clearInterval(interval);
        }, timeout);
    }
};

/**
 * Description: Get element in data layer that passed the test
 * 
 * @param {function|string}     testFnOrProp    Test executed on data layer element. If function, called with element as input, if string, check if element has the property
 * @param {function}            callback        Callback function called when test pass
 */
dlReader.searchElement = function (testFnOrProp, callback) {
    return searchElement(testFnOrProp, callback);
};

/**
 * Description: Get element in data layer that passed the test function. If called by waitDataLayerElement stop interval and resolve promise with element
 * 
 * @param {function|string}     testFnOrProp     Test executed on data layer element. If function, called with element as input, if string, check if element has the property
 * @param {function}            resolve         Callback function called when test pass
 * @param {object}              interval        The window setInterval object that must be clear when the element is found  
 */
function searchElement(testFnOrProp, callback, interval) {
    var dataLayer = window.dataLayer || [];
    for (var i = 0; i < dataLayer.length; i++) {
        if (checkCondition(testFnOrProp, dataLayer[i])) {
            if (interval) {
                window.clearInterval(interval);
            }
            if (callback) {
                callback(dataLayer[i]);
                return;
            } else {
                return dataLayer[i];
            }
        }
    }
}

/**
 * Description: Get element in data layer that passed the test function. If the control is a string check if the element has the property, else call as function
 *
 * @param {function|string}    testFnOrProp    Test executed on data layer element. If function, called with element as input, if string, check if element has the property
 * @param {object}              element         Data layer element
*/
function checkCondition(testFnOrProp, element) {
    if (typeof testFnOrProp === 'string' || testFnOrProp instanceof String) {
        return element[testFnOrProp];
    } else {
        return testFnOrProp(element);
    }
}

/**
 * Disciption: set order values based on dataLayer purchase element
 * 
 * @param {object}              element         dataLayer element that contains order information
 * @param {function}            callback        Callback function called when test pass 
 */
dlReader.scrapeDlOrder = function (element, callback) {
    var actionField = _.get(element, "ecommerce.purchase.actionField");
    var products = _.get(element, "ecommerce.purchase.products");
    order.currency = _.get(element, "ecommerce.currencyCode");
    if (actionField) {
        order.id = order.number = actionField.id;
        order.gross = _.parseFloat(actionField.revenue);
        order.discountCode = actionField.coupon;

        if (products) {
            var orderGross = 0;
            for (var i = 0; i < products.length; i++) {
                var prod = products[i];
                order.products.add(prod);
                orderGross += _.parseFloat(prod.price) * _.parseInt(prod.quantity);
            }
            if (orderGross && order.gross < orderGross) {
                order.discount = _.parseFloat((orderGross - order.gross).toFixed(2));
                order.gross = _.parseFloat(orderGross.toFixed(2));
            }
        }
    }
    callback();
};

/**
 * Description: monitor dataLayer to see when a product is added or removed from the cart
 */
dlReader.monitorCartChange = function () {
    watchDataLayerForElement(function (element) {
        return element && (element.event === "add_to_cart" || element.event === "removeFromCart");
    }, function (element) {
        cart.clear();
        var action = cart.cartAction = "add";
        if (element.event === "removeFromCart") {
            action = "remove";
            cart.cartAction = "subtract";
        }
        cart.products.add(_.get(element, "ecommerce." + action + ".products[0]"));
        platform.trackPage();
    });
};

},{"__hodor/platform.js":11,"__hodor/state.js":13,"__hodor/utils.js":14}],2:[function(require,module,exports){
var log = require('__hodor/log.js');
var platform = require('__hodor/platform.js');
var state = require('__hodor/state.js');
var stash = require('__hodor/stash.js');
var _ = require('__hodor/utils.js');
var capabilities = require('__hodor/capabilities.js');
var $ = require('__hodor/jquery.js');
var JSON = window.JSON;
var gtm = require("./gtm");

var user = state.user;
var page = state.page;
var product = page.product;
var cart = state.cart;
var order = state.order;
var baseAmount = 40;

stash.load();

platform.capabilities.applyToCartUrl = '/discount/%c?redirect=' + window.location.pathname + window.location.search + '#FP_COUPON_APPLIED';

page.type = getPageType();

if ($(".icon-user > span").text() === "My Account") {
  page.data.isLoggedIn = 1;
}

if (page.type === "order") {
  scrapeOrder();
  trackOrder();
} else {
  var tracked;
  var interval = window.setInterval(function () {
    if ($("footer").length) {
      scrapeCart();
      trackPage();
      window.clearInterval(interval);
      tracked = true;
    }
  }, 500);
}

function getPageType() {
  var pathname = window.location.pathname;
  if (/\/thank_you/.test(pathname)) {
    return "order";
  }
  if (pathname === "/") {
    return "home";
  }
  if (/\/products\//.test(pathname)) {
    monitorCart();
    scrapeCategory();
    scrapeProduct();
    return "prod";
  }
  if (/\/collections\//.test(pathname)) {
    scrapeCategory();
    return "cat";
  }
  if (/\/cart/.test(pathname)) {
    return "cart";
  }
  return "page";
}

function scrapeOrder() {
  var Shopify = window.Shopify;
  if (Shopify) {
    var checkout = Shopify.checkout;
    order.id = order.number = checkout.order_id;
    order.currency = "GBP";
    try {
      order.discountCode = checkout.discount.code;
      order.discount = _.parseFloat(checkout.discount.amount);
    } catch (error) {}
    order.gross = 0;
    order.cartAction = "repeat";
    if (checkout.line_items) {
      for (var i = 0; i < checkout.line_items.length; i++) {
        var prod = checkout.line_items[i];
        order.gross += prod.quantity * _.parseFloat(prod.price);
      }
    }
    if (order.discount > order.gross) {
      order.discount = 0;
    }
  }
}

function scrapeCart() {
  var limitsifyClient = window.appikon;
  if (limitsifyClient) {
    for (var i = 0; i < limitsifyClient.cart.items.length; i++) {
      var prod = cart.products.add(limitsifyClient.cart.items[i]);
      prod.price = prod.price / 100;
    }
    cart.updateTotals();
    cart.gross = limitsifyClient.cart.total_price;
    cart.discountCode = $(".footer-code").text();
    cart.discount = limitsifyClient.cart.total_discount;
  }
}

function scrapeCategory() {
  var catRegex = /\/collections\/([a-zA-Z-]+)\/?/;
  if (catRegex.test(window.location.pathname)) {
    var cat = catRegex.exec(window.location.pathname)[1];
    page.categories.add(cat, cat);
  }
}

function scrapeProduct() {
  if (window.item) {
    var productInfo = window.item;
    product.id = product.sku = productInfo.ProductID;
    product.price = _.parseFloat($(".price-label:first").text());
    product.url = productInfo.URL;
    product.image = productInfo.ImageURL;
    product.name = productInfo.Name;
  }
}

function monitorCart() {
  gtm.watchDataLayerForElement(function (element) {
    return element && element[1] === "add_to_cart";
  }, function (element) {
    trackPage();
  });
}

function trackPage(monitor) {
  // if (page.type === "cart") {
  //   log(JSON.stringify(state.cart, null, 2));
  // } else {
  //   log(JSON.stringify(state.page, null, 2));
  // }
  fetch('https://solgar.co.uk/cart.json?vsly=t').then(function (response) {
    if (!response.ok) {
      platform.trackPage();
    }
    return response.json();
  }).then(function (cartData) {
    var items = cartData.items;
    cart.clear();
    cart.cartAction = "override";
    cart.gross = cartData.total_price / 100;
    for (var i = 0; i < items.length; i++) {
      items[i].price = items[i].price / 100;
      cart.products.add({
        id: items[i].id + '-' + items[i].price * 100,
        sku: items[i].variant_id,
        name: items[i].title,
        quantity: items[i].quantity,
        price: items[i].price
      });
    }
    if (baseAmount > cart.gross) {
      page.data.toAmount = _.parseFloat(baseAmount - cart.gross).toFixed(2);
    } else {
      page.data.toAmount = '0';
    }
    platform.trackPage();
  });
}

function trackOrder() {
  // log(JSON.stringify(state.order, null, 2));
  platform.trackOrder();
}

},{"./gtm":1,"__hodor/capabilities.js":5,"__hodor/jquery.js":9,"__hodor/log.js":10,"__hodor/platform.js":11,"__hodor/stash.js":12,"__hodor/state.js":13,"__hodor/utils.js":14}],3:[function(require,module,exports){
"use strict";

var Emitter = require("./emitter");

// `adaptor` is declared at the top of the IIFE during the build process.
var adaptor = module.exports = {};

var fanplayr = window.fanplayr = window.fanplayr || {};
adaptor.api = window.fanplayr_api = window.fanplayr_api || [];

Emitter.mixin(adaptor);

var log = adaptor.log = require("./log");
var config = adaptor.config = require("./config");
var _ = adaptor.utils = require("./utils");

adaptor.isInFrame = function () {
  var result = true;
  try {
    var w = window;
    result = w.top === w || w.top === w.self || !w.frameElement;
  } catch (ex) {}
  return !result;
};

var isCollector = config.collector;

if (config.version) {
  adaptor.version = config.version;
}

var didInit;
function init(fanplayr) {
  if (!didInit) {
    didInit = true;

    if (config.accountKey) {
      fanplayr.platform.config.accountKey = config.accountKey;
    }

    var platformUtils = fanplayr.require("platform/utils");
    for (var key in platformUtils) {
      _[key] = platformUtils[key];
    }

    adaptor.createStash = fanplayr.require("platform/stash").create;

    var stash = adaptor.stash = adaptor.createStash(_.merge({
      name: "adaptor",
      scope: "domain"
    }, config.stash || {}));

    adaptor.fanplayr = fanplayr;
    adaptor.$ = fanplayr.$;
    adaptor._ = _;

    stash.load(function () {
      var entry = require('__project/main.js');

      fanplayr.adaptor = _.merge({}, adaptor, entry || {});

      adaptor.emit("ready", fanplayr, fanplayr.$, _);
    });
  }
}

var isInFrame = adaptor.isInFrame();
if (!isInFrame || fanplayr.allowInFrame || config.allowInFrame) {
  // Prevent adaptor from loading multiple times.
  if (!fanplayr.adaptor || isCollector) {
    fanplayr.adaptor = adaptor;

    var initPlatform = function initPlatform() {
      // Boot fanplayr.
      require("./fanplayr-init")(init, adaptor.environment, config);
    };

    var bootFn = require('__hodor/boot.js');
    if (typeof bootFn === "function") {
      bootFn(initPlatform);
    } else {
      // Give implementing adaptor a chance to modify `adaptor.environment`.
      setTimeout(initPlatform, 0);
    }
  } else {
    log("Adaptor already loaded. Skip.");
    var reloadFn = fanplayr.adaptor && fanplayr.adaptor.onReload;
    if (typeof reloadFn === "function") {
      reloadFn();
    }
  }
} else {
  log("Adaptor prevent loading in frame: " + (window.frameElement && window.frameElement.src));
}

},{"./config":6,"./emitter":7,"./fanplayr-init":8,"./log":10,"./utils":14,"__project/main.js":2,"__hodor/boot.js":4}],4:[function(require,module,exports){
// Empty boot file that does nothing.
"use strict";

},{}],5:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").fanplayr.platform.capabilities;

},{"./adaptor":3}],6:[function(require,module,exports){
"use strict";

// Magically replaced, do not edit.
module.exports = {
  "type": "adaptor",
  "name": "solgar",
  "version": "1.0.12",
  "accountKey": "ab385d78f75e7fe49fdd43a3b757058f",
  "adaptorVersion": 1
};

},{}],7:[function(require,module,exports){
"use strict";

function Emitter() {
  this.events = {};

  // Last emitted values.
  this.emitCache = {};
}

var arraySlice = Array.prototype.slice;

Emitter.mixin = function (obj) {
  var emitter = new Emitter();

  obj.on = function () {
    return emitter.on.apply(emitter, arraySlice.call(arguments));
  };

  obj.emit = function () {
    return emitter.emit.apply(emitter, arraySlice.call(arguments));
  };

  obj.$emitter = emitter;

  return obj;
};

Emitter.prototype = {
  on: function on(eventType, options, cb) {
    var self = this;

    if (typeof options === "function") {
      var temp = cb;
      cb = options;
      options = temp;
    }

    if (!options) {
      options = {};
    }

    var listeners = self.events[eventType];
    if (!listeners) {
      listeners = self.events[eventType] = [];
    }

    var listener = {
      options: options,
      cb: cb
    };

    if (options.init) {
      var cached = self.emitCache[eventType];
      if (cached) {
        self.invoke(listener, cached);
      }
    }

    listeners.push(listener);
  },

  emit: function emit(eventType) {
    var self = this;

    var args = arraySlice.call(arguments, 1);
    self.emitCache[eventType] = args;

    var listeners = self.events[eventType];
    if (listeners) {
      for (var i = 0, len = listeners.length; i < len; i++) {
        self.invoke(listeners[i], args);
      }
    }
  },

  invoke: function invoke(listener, args) {
    listener.cb.apply(listener.options.context || listener.cb, args);
  }
};

module.exports = Emitter;

},{}],8:[function(require,module,exports){
"use strict";

var log = require("./log");

module.exports = function (initFn, environment, config) {
  (function (window, document) {
    var fanplayr = window.fanplayr;
    var fanplayrReady = "fanplayr_ready";

    /*
      fanplayr._loadState
        falsy: not initalized
        1: loading
        2: ready
    */

    if (fanplayr) {
      var loadState = fanplayr._loadState;

      if (loadState === 2) {
        // Already loaded.
        initFn(fanplayr);
      }

      var origFn = window[fanplayrReady];
      window[fanplayrReady] = function (f, p) {
        if (origFn) {
          origFn(f, p);
        }
        initFn(f);
      };

      if (!loadState) {
        // Fanplayr hasn't begun loading, so load it.
        var script = document.createElement("script");
        script.async = true;
        script.src = "//cdn.fanplayr.com/client/" + (environment || "production") + "/loader.js";
        var first = document.getElementsByTagName("script")[0];
        var mode = config.injectMode || 'beforeFirstScript';
        if (first && mode === 'beforeFirstScript') {
          first.parentNode.insertBefore(script, first);
        } else if (document.head && mode === 'head') {
          document.head.appendChild(script);
        } else if (document.body) {
          document.body.appendChild(script);
        }
      }
    }
  })(window, document);
};

},{"./log":10}],9:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").fanplayr.$;

},{"./adaptor":3}],10:[function(require,module,exports){
"use strict";

var console, logFn;

try {
  console = window.console || window.top.console;
  logFn = console && console.log;
} catch (e) {}

var noop = function noop() {};

function create(name) {
  var log = noop;
  if (logFn) {
    if (typeof logFn.bind === "function") {
      log = logFn.bind(console, "[" + name + "]");
    } else {
      log = function log() {
        var args = Array.prototype.slice.call(arguments);
        args.unshift("[" + name + "]");
        logFn(args.join(""));
      };
    }
  }

  log.create = create;
  log.info = log;
  log.error = log;

  return log;
}

module.exports = create("adaptor");

},{}],11:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").fanplayr.platform;

},{"./adaptor":3}],12:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").stash;

},{"./adaptor":3}],13:[function(require,module,exports){
"use strict";

module.exports = require("./adaptor").fanplayr.platform.state;

},{"./adaptor":3}],14:[function(require,module,exports){
"use strict";

// modules.exports = require("./req")("platform/utils");

var _ = module.exports = {};
var log = require("./log");

_.parseInt = function (text, defaultValue) {
  var value = _parseFloat(text, defaultValue);
  if (typeof value === "number") {
    return Math.floor(value);
  }
  return value;
};

/*
  Extracts the first matched float from a string.

  @param string text       The input string.
  @param * defaultValue    The default value to return if parsing fails.
  @param string chars      Special characters.

  About `chars`:
    - Must include at least two characters.
    - The first character must be the thousands separator.
    - The second character must be the decimal character.
    - Any other characters will be matched as part of the number, but stripped before converting to a float.
*/
_.parseFloat = _parseFloat;
function _parseFloat(text, defaultValue, chars) {
  // Get defaults.
  if (typeof defaultValue === "undefined") {
    defaultValue = _parseFloat.defaultValue;
  }
  chars = chars || _parseFloat.chars;

  text = String(text);
  var value = defaultValue;
  if (text) {
    // Find the first occurance of a group of numbers and special characters. Must match:
    // - Optional negative character `-`
    // - Optional single special character
    // - One or more digits
    // - Zero or more digits and special characters
    var pattern = new RegExp("(-?[" + chars.substr(0, 2) + "]?\\d+[0-9" + chars + "]*)");
    var match = text.match(pattern);
    if (match) {
      // Build a regular expression of bad characters to strip out.
      // The first char is the separator.
      var badChars = chars.charAt(0);
      if (chars.length > 2) {
        // Add any others.
        badChars += chars.substr(2);
      }
      pattern = new RegExp("[" + badChars + "]", "g");

      // Strip bad characters.
      text = match[1].replace(pattern, "");

      // Replace the decimal character with an actual period
      // and parse as float.
      value = parseFloat(text.replace(chars.charAt(1), "."));

      if (isNaN(value)) {
        return defaultValue;
      }
    }
  }
  return value;
};

_parseFloat.defaultValue = 0;
_parseFloat.chars = ",.";

var decodeHtmlEl;
_.decodeHtml = function (value) {
  if (value) {
    if (!decodeHtmlEl) {
      decodeHtmlEl = document.createElement("div");
    }
    decodeHtmlEl.innerHTML = value;
    return decodeHtmlEl.firstChild.nodeValue;
  }
  return value;
};

_.waitUntil = function (evaluate, intervalMs, callback) {
  if (arguments.length === 2) {
    callback = intervalMs;
    intervalMs = 500;
  }

  if (typeof evaluate === "string") {
    var evalPath = evaluate;
    evaluate = function evaluate() {
      return eval(evalPath);
    };
  }

  var iterator = function iterator() {
    var value = evaluate();
    if (value) {
      callback(value);
    } else {
      setTimeout(iterator, intervalMs);
    }
  };

  iterator();
};

},{"./log":10}]},{},[3]);